import React from "react";
import * as s from "./MemberPopup.module.scss";
import arrowLine from "../../images/leftborder.png";
import { Container } from "react-bootstrap";
import DOMPurify from "isomorphic-dompurify";

const MemberPopup = ({ member, closePopup }) => {
    const {
        title,
        featuredImage,
        acfEmployees: { designation },
        content,
    } = member;

    return (
        <Container>
            <div className={s.popup}>
                <div className={s.popup__left}>
                    <div className={s.member__image}>
                        {featuredImage && <img src={featuredImage?.node?.localFile?.publicURL} alt={`${title} - ${designation}`} />}
                    </div>
                    <div className={s.popup__closeBtn} onClick={closePopup}>
                        <img src={arrowLine} className={s.popup__closeBtn__line} alt="Back to Team" />
                        <span className={s.popup__closeBtn__arrow} />
                        Back to Team
                    </div>
                </div>
                <div className={s.member__content}>
                    <h3
                        className={s.member__name}
                        dangerouslySetInnerHTML={{
                            __html: DOMPurify.sanitize(title),
                        }}
                    />

                    <p
                        className={s.member__designation}
                        dangerouslySetInnerHTML={{
                            __html: DOMPurify.sanitize(designation),
                        }}
                    />

                    {content && (
                        <div
                            className={`small-body-copy ${s.member__bio}`}
                            dangerouslySetInnerHTML={{
                                __html: DOMPurify.sanitize(content),
                            }}
                        />
                    )}
                </div>
            </div>
        </Container>
    );
};

export default MemberPopup;
