// extracted by mini-css-extract-plugin
export var member__bio = "MemberPopup-module--member__bio--wmWsD";
export var member__content = "MemberPopup-module--member__content--8c9IB";
export var member__designation = "MemberPopup-module--member__designation--24aC8";
export var member__image = "MemberPopup-module--member__image--ER1D9";
export var member__name = "MemberPopup-module--member__name--ZgoLl";
export var popup = "MemberPopup-module--popup--Qgntb";
export var popup__closeBtn = "MemberPopup-module--popup__closeBtn--z6wKV";
export var popup__closeBtn__arrow = "MemberPopup-module--popup__closeBtn__arrow--UTJyM";
export var popup__closeBtn__line = "MemberPopup-module--popup__closeBtn__line--69mad";
export var popup__left = "MemberPopup-module--popup__left--ao9Z9";