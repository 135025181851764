import React from "react";
import { Link } from "gatsby";
import { Container, Col, Row } from "react-bootstrap";
import * as s from "./CallToAction.module.scss";
import arrow from "../../images/cta/cta-arrow.svg";
import vector from "../../images/cta/vector-line.svg";
import DOMPurify from "isomorphic-dompurify";

const CallToAction = ({ content: { title = "", description = "", link } }) => {
    return (

        <Container fluid className={s.cta}>
            <Row>
                <Col>
                    <Link to={link?.url || "/"} target={link?.target} className={s.cta__card}>
                        <div className={s.cta__card__arrow}>
                            <img src={arrow} alt="" />
                        </div>
                        <img className={s.cta__vector} src={vector} alt="vector-line" />

                        {title?.trim() !== "" && (
                            <h2
                                className={` ${s.cta__title} white`}
                                dangerouslySetInnerHTML={{
                                    __html: DOMPurify.sanitize(title),
                                }}
                            />
                        )}
                        <div
                            className={s.cta__description}
                            dangerouslySetInnerHTML={{
                                __html: DOMPurify.sanitize(description),
                            }}
                        />
                    </Link>
                </Col>
            </Row>
        </Container>
    );
};

export default CallToAction;
