import React, { Fragment } from "react";
import DOMPurify from "isomorphic-dompurify";

const FilterNavText = (props) => {
    return (
        <Fragment>
            <div>
                <h4
                    className="d-none d-md-block"
                    dangerouslySetInnerHTML={{
                        __html: DOMPurify.sanitize(props.title),
                    }}
                />
            </div>
        </Fragment>
    );
};

export default FilterNavText;
