import React from "react";
import { Col } from "react-bootstrap";
import * as s from "./Member.module.scss";
import border from "../../images/leftborder.png";
import DOMPurify from "isomorphic-dompurify";

const Member = ({ id, name, isHidden, designation, image, focusMember }) => {
    return (
        <Col xs={6} md={4} hidden={isHidden} className={s.member} onClick={() => focusMember(id)}>
            <div className={s.member__img}>{image && <img src={image} alt={`${name} - ${designation}`} />}</div>

            <div className={s.member__data}>
                <h4 className={s.member__name}>
                    <span className={s.member__arrow} />
                    <span className={s.member__name__text}>{name}</span>
                </h4>

                <div
                    className={`small-body-copy ${s.member__designation}`}
                    dangerouslySetInnerHTML={{
                        __html: DOMPurify.sanitize(designation),
                    }}
                />
            </div>
        </Col>
    );
};

export default Member;
