// extracted by mini-css-extract-plugin
export var leadershipFilter = "LeadershipFilter-module--leadershipFilter--0iNei";
export var leadershipFilter__body = "LeadershipFilter-module--leadershipFilter__body--tAeEu";
export var leadershipFilter__heading = "LeadershipFilter-module--leadershipFilter__heading--M1axu";
export var leadershipFilter__image = "LeadershipFilter-module--leadershipFilter__image--U8mPb";
export var leadershipFilter__image__item = "LeadershipFilter-module--leadershipFilter__image__item--ReF32";
export var leadershipFilter__image__item__active = "LeadershipFilter-module--leadershipFilter__image__item__active--BJ7xp";
export var leadershipFilter__leadershipList = "LeadershipFilter-module--leadershipFilter__leadershipList--fF8zD";
export var leadershipFilter__lists = "LeadershipFilter-module--leadershipFilter__lists--ILbZK";
export var leadershipFilter__membersList = "LeadershipFilter-module--leadershipFilter__membersList--SGWIi";
export var sectionTitle = "LeadershipFilter-module--sectionTitle--xQwZN";