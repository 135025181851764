import React from "react";
import { Col } from "react-bootstrap"
import * as s from "./MemberSimple.module.scss";
import DOMPurify from "isomorphic-dompurify";

const MemberSimple = ({ name, isHidden, designation }) => {
    return (
        <Col xs={12} sm={6} md={4} className={s.member__simple} hidden={isHidden}>
            <h4
                className={s.member__name}
                dangerouslySetInnerHTML={{
                    __html: DOMPurify.sanitize(name),
                }}
            />
            <div
                className={`small-body-copy ${s.member__designation}`}
                dangerouslySetInnerHTML={{
                    __html: DOMPurify.sanitize(designation),
                }}
            />
        </Col>
    );
};

export default MemberSimple;
