import React from "react";
import { graphql } from "gatsby";
import { Seo } from "../components/Helpers/Seo";
import DOMPurify from "isomorphic-dompurify";
import Header from "../components/Header/Header";
import { Col, Container, Row } from "react-bootstrap";
import Layout from "../components/Layout/Layout";
import * as s from "../styles/pages/about-us.module.scss";
import CallToAction from "../components/CallToAction/CallToAction";
import SectionDivider from "../components/SectionDivider/SectionDivider";
import LeadershipFilter from "../components/LeadershipFilter/LeadershipFilter";

// call out seo properties here
export const Head = ({ data }) => (
    <Seo title={data.wpPage.seo.title} description={data.wpPage.seo.metaDesc} keywords={data.wpPage.seo.metaKeywords} />
);

const AboutUs = ({ data }) => {
    const acfCta = data.wpPage.ctaGlobal;
    const { heroSection, employeesSection } = data.wpPage.acfAboutPage;
    const employees = data.allWpEmployee.nodes;
    const employeeTypes = data.allWpEmployeeCategory.nodes;

    return (
        <Layout>
            <Container fluid className={`${s.aboutUs} pattern-background`}>
                <Header />

                <section>
                    <Container className={s.aboutUs__mission}>
                        <Row>
                            <Col sm={12} lg={6}>
                                <h1
                                    dangerouslySetInnerHTML={{
                                        __html: DOMPurify.sanitize(heroSection?.title),
                                    }}
                                />
                            </Col>
                            <Col sm={12} lg={6}>
                                <div
                                    dangerouslySetInnerHTML={{
                                        __html: DOMPurify.sanitize(heroSection?.description),
                                    }}
                                />
                            </Col>
                        </Row>

                    </Container>
                </section>
                <SectionDivider hidePattern="true" />
                <section>
                    <a id="leadership" name="leadership" />
                    <Container fluid className={s.aboutUs__leadership} style={{ paddingTop: 90 + "px" }}>
                        <Row>
                            <Col>
                                <LeadershipFilter
                                    content={employeesSection}
                                    employees={employees}
                                    employeeTypes={employeeTypes}
                                />
                            </Col>
                        </Row>
                    </Container>
                </section>
            </Container>

            <Container fluid style={{ position: "relative" }}>
                <CallToAction content={acfCta} />
            </Container>
        </Layout>
    );
};

export default AboutUs;

export const pageQuery = graphql`
    query {
        wpPage(databaseId: { eq: 18 }) {
            seo {
                metaDesc
                title
                metaKeywords
            }
            acfAboutPage {
                heroSection {
                    description
                    title
                }
                employeesSection {
                    title
                    description
                }
            }
            ctaGlobal {
                description
                title
                link {
                    url
                    title
                    target
                }
            }
        }
        allWpEmployeeCategory(sort: { fields: databaseId }) {
            nodes {
                name
                databaseId
            }
        }
        allWpEmployee {
            nodes {
                databaseId
                title
                content
                featuredImage {
                    node {
                        localFile {
                            publicURL
                        }
                    }
                }
                acfEmployees {
                    designation
                }
                employeeCategories {
                    nodes {
                        databaseId
                    }
                }
            }
        }
    }
`;
