import React, { useState, useRef, Fragment } from "react";
import DOMPurify from "isomorphic-dompurify";
import { Container, Row, Col } from "react-bootstrap";
import FilterNav from "./FilterNav";
import FilterNavText from "./FilterNavText";
import Member from "../Member/Member";
import MemberSimple from "../Member/MemberSimple";
import MemberPopup from "../Member/MemberPopup";
import * as styles from "./LeadershipFilter.module.scss";

const leadershipCategory = 143;

const LeadershipFilter = ({ content, employees, employeeTypes }) => {
    const [activeCategory, setActiveCategory] = useState(leadershipCategory);
    const [activeIndex, setActiveIndex] = useState(0);
    const [selectedMember, setSelectedMember] = useState({});
    const [showPopup, setShowPopup] = useState(false);
    const teamRef = useRef(null);

    const clickableContentItemClickHandler = (tab, id) => {
        setShowPopup(false);
        setActiveIndex(tab);
        setActiveCategory(id);
    };

    const focusMemberHandler = (id) => {
        setSelectedMember(employees.find((employee) => id === employee.databaseId));
        setShowPopup(true);

        window.scrollTo({
            behavior: "smooth",
            top: teamRef.current.offsetTop - 25,
        });
    };

    return (
        <Fragment>
            <Container className={styles.leadershipFilter} ref={teamRef}>
                <Row>
                    <Col lg={{ span: 6, order: 2 }}>
                        {/* show title here if at desktop breakpoint */}
                        <Row className={`${styles.sectionTitle} d-lg-none`}>
                            <h2
                                dangerouslySetInnerHTML={{
                                    __html: DOMPurify.sanitize(content.title),
                                }}
                            />
                        </Row>
                        <div
                            className={styles.leadershipFilter__body}
                            dangerouslySetInnerHTML={{
                                __html: DOMPurify.sanitize(content.description),
                            }}
                        />
                    </Col>
                    <Col lg={{ span: 6, order: 1 }}>
                        {/* show title here from mobile to desktop, hide at desktop*/}
                        <Row className={`${styles.sectionTitle} d-none d-lg-block`}>
                            <h2
                                dangerouslySetInnerHTML={{
                                    __html: DOMPurify.sanitize(content.title),
                                }}
                            />
                        </Row>
                        <Row style={{ marginRight: 0 + "px" }}>
                            {employeeTypes.map((item, index) => (
                                <FilterNav
                                    title={item?.name}
                                    itemIndex={index}
                                    key={index}
                                    activeItemIndex={activeIndex}
                                    isLast={index === employeeTypes.length - 1}
                                    heading={item.name}
                                    onClick={(e) => clickableContentItemClickHandler(e, item.databaseId)}
                                />
                            ))}
                        </Row>
                        <Row className="d-md-none" style={{ marginRight: 0 + "px" }}>
                            {employeeTypes.map((item, index) => {
                                return (
                                    activeIndex === index && (
                                        <div key={index}>
                                            <FilterNavText title={item.name} />
                                        </div>
                                    )
                                );
                            })}
                        </Row>
                    </Col>
                </Row>
            </Container>

            {/* start people/leadership team display here */}
            <Container className={styles.leadershipFilter__lists}>
                <Row className={styles.leadershipFilter__leadershipList}>
                    {showPopup ? (
                        <MemberPopup member={selectedMember} closePopup={() => setShowPopup(false)} />
                    ) : (
                        employees.map((member) => {
                            const {
                                databaseId: id,
                                title,
                                acfEmployees: { designation },
                                featuredImage,
                                employeeCategories: {
                                    nodes: [category],
                                },
                            } = member;

                            if (Number(category.databaseId) === leadershipCategory) {
                                return (
                                    <Member
                                        key={id}
                                        isHidden={activeCategory !== category.databaseId}
                                        id={id}
                                        name={title}
                                        designation={designation}
                                        image={featuredImage?.node?.localFile?.publicURL}
                                        focusMember={focusMemberHandler}
                                    />
                                );
                            }
                        }))
                    }
                </Row>
                <Row className={styles.leadershipFilter__membersList}>
                    {/* use separate maps to display the groups to prevent cross styling issues */}
                    {employees.map((m) => {
                        const {
                            databaseId: id,
                            title,
                            acfEmployees: { designation },
                            employeeCategories: {
                                nodes: [category],
                            },
                        } = m;
                        if (Number(category.databaseId !== leadershipCategory)) {
                            return (
                                <MemberSimple
                                    key={id}
                                    isHidden={activeCategory !== category.databaseId}
                                    name={title}
                                    designation={designation}
                                />
                            );
                        }
                    })}
                </Row>
            </Container>
        </Fragment>
    );
};

export default LeadershipFilter;
